import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import authRoutes from './authRoutes';
import adminRoutes from './adminRoutes';
import authHelper from '../util/authHelper';

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  mode: 'history',
  routes: [...adminRoutes,...routes, ...authRoutes], // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {selector: to.hash};
    }
    return {x: 0, y: 0};
  }
});

router.beforeEach(async (to, from, next) => {
  // if (to.name !== 'Login' && !router.app.$store.getters.getIsPasswordChanged) next({ name: 'ChangePassword' })
  // else next()
  let $store = router.app.$store;
  let authUser = $store.getters.getAuthUser;
  if(!authUser && authHelper.getAuthToken('subUser')){
    try {
      await $store.dispatch('_getSubUser');
      authUser = $store.getters.getAuthUser;
      window.localStorage.setItem('customizePipeline', authUser.administrative_rights ?? 0 )
      $store.commit('toggleTermsAndConditionsModal', !!(authUser.type == 'program_user' && (authUser.self_service || [1].includes(authUser.sub_user.tier)) && !authUser.terms_and_conditions_check));
    } catch (error) {
      authHelper.deleteAuthToken('subUser');
      authHelper.deleteRefreshToken('subUser');
      next({name: "Login"})
      return;
    }
  }
  let isAuthenticated = authUser ? true : false;
  
  let require_auth = to.matched.some((el)=>el.meta.auth) || false;
  let require_super_admin = to.matched.some((el)=>el.meta.super_admin) || false;

    if(require_auth && !isAuthenticated){
      next({name: "Login"})
      return;
    }
    else if(require_auth && isAuthenticated){
      if(authUser.type == 'super_admin' && require_super_admin == false ){
        next({name:'admin.dashboard'});
        return;
      }
      else if(authUser.type != 'super_admin' && require_super_admin == true ){
         next({name:'Dashboard'});
         return;
      }
    }
    next();

})

export default router;
